import { createTheme } from '@mui/material';

export const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

// A custom theme for this app
let theme = createTheme(themeOptions);
theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.primary.main,
    },
  },
});

export default theme;
